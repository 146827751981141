import React from 'react';
import keyStethImage from '../icons/GTechnology-Logo-remove.png'   // Import the image based on the given directory structure

function Jumbotron() {
  return (
    <div className="jumbotron-mv-main">
        <div className="heading">Trainings and Internships
            <div className="content">Harnessing the Power of AI Education, Through Tailored Tech-Driven Training Modules, Cultivating the Innovators of the Next Era.</div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>  
  );
}

export default Jumbotron;

