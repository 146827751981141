import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import user from '../icons/user.png';
import manaswinidasari from '../icons/manaswinidasari.jpg';
import swapna from '../icons/swapnap.jpg';
import pratyusha from '../icons/pratyushav.jpg';
import meena from '../icons/meena.jpg';
import srinija from '../icons/srinija.jpg';
import SaiPriya from '../icons/SaiPriya.png';
import Akhila from '../icons/Akhila.jpg';
import Sameera from '../icons/Sameera.jpg';
import SonyPriyaSurapaneni from '../icons/SonyPriyaSurapaneni.jpg';
import SowmyaReddy from '../icons/SowmyaReddy.jpg';
import VarshiniS from '../icons/VarshiniS.jpg';

function InternsPresentPast() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
    };

    return (
        <div className="testimonials">
            <label className="question-working">Spotlight on Our Interns</label>
            <div className="content-answer">
                We are delighted to shine a light on the talented interns who are making significant strides within our organization. Through hands-on experience and mentorship from our top professionals, they are mastering both basic and advanced skills. Join us in recognizing their hard work and enthusiasm as they contribute to our company’s future. Discover the stories of these young innovators who are set to lead the way.
            </div>
            <div className="content-answer"></div>
            <div className="testimonials">
                <Slider {...settings}>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={manaswinidasari} alt="Manaswini Dasari"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Manaswini Dasari</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis (IUPUI), Indianapolis, USA<br />
                                        - Bachelor of Dental Surgery
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Pursuing a Master of Science in Health Informatics at Indiana University-Purdue University Indianapolis, USA
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Successfully led and completed an Electronic Health Record Data Science Project focusing on pathology data extraction and visualization utilizing Python and machine learning methodologies. Manaswini has been an outstanding Data Science intern throughout her time with us. Her unwavering dedication and commitment to her work have been evident, always going above and beyond to achieve outstanding results. She possesses a remarkable ability to articulate her ideas clearly and concisely, making her communication highly effective.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        What sets Manaswini apart is her commendable eagerness to learn and grow in the field of Data Science. Continuously seeking new knowledge and skills, she actively enhances her performance and embraces diverse perspectives, leading to innovative problem-solving approaches. Her excellent task prioritization ensures timely completion and smooth progress of projects.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Manaswini's exceptional contributions have had a positive impact on the success of the projects, notably the Pathology data extraction and visualization using Machine Learning, which she completed successfully.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={pratyusha} alt="Prathyusha Vajinepally"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Prathyusha Vajinepally</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis (IUPUI), Indianapolis, USA<br />
                                        - Bachelor of Dental Surgery
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Pursuing a Master of Science in Health Informatics at Indiana University-Purdue University Indianapolis, USA
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Prathyusha Vajinepally has excelled in her role as a Data Science intern, leading and successfully completing an Electronic Health Record Data Science Project focused on pathology data extraction and visualization. Utilizing Python and machine learning methodologies, she has demonstrated exceptional skills in both technical execution and innovative problem-solving.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Prathyusha's commitment to her work is evident through her dedication and ability to consistently deliver outstanding results. Her clear and effective communication skills make her an asset in articulating complex ideas and findings. What truly sets Prathyusha apart is her eagerness to continuously learn and grow in the field of Data Science. She actively seeks out new knowledge and embraces diverse perspectives, which enhances her performance and fosters innovative solutions.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Additionally, Prathyusha's excellent task prioritization has ensured the timely and successful completion of projects. Her contributions to the pathology data extraction and visualization project using machine learning have significantly impacted its success. Her proactive approach to learning and skill development is a testament to her growth and value as a team member.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Overall, Prathyusha's remarkable ability to apply machine learning techniques and her dedication to her professional development make her a standout intern and a valuable addition to our team.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={swapna} alt="Swapna Ponduri"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Swapna Ponduri</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis (IUPUI), Indianapolis, USA<br />
                                        - Bachelor of Pharmacy
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Pursuing a Master of Science in Health Informatics at Indiana University-Purdue University Indianapolis, USA
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Swapna Ponduri has distinguished herself as an exceptional Data Science intern, leading a comprehensive project on Electronic Health Records with a focus on drug database management and drug interaction analysis. Her work involved extracting and visualizing pathology data using advanced Python and machine learning techniques.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Swapna's dedication and commitment to her role have been outstanding. She consistently goes above and beyond to achieve remarkable results. Her ability to clearly and concisely articulate complex ideas has made her communication highly effective, ensuring smooth collaboration with her team.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        A standout feature of Swapna's performance is her proactive approach to learning and development. She has demonstrated a keen interest in mastering new skills and expanding her knowledge, particularly in drug databases and drug interaction analysis. Her work involved integrating and managing extensive drug-related datasets, applying machine learning algorithms to identify and visualize drug interactions, and ensuring accurate data handling and analysis.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Her excellent task prioritization and problem-solving skills have led to the successful completion of projects. Notably, Swapna's contributions to the drug database project, including the development of robust visualization tools for drug interactions, have been instrumental in enhancing the overall effectiveness of the project.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Swapna’s impressive technical abilities and her dedication to continuous improvement have had a significant positive impact on the team. Her innovative approach and effective communication skills make her a valuable asset and a future leader in the field of Data Science.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={meena} alt="Meena Sree Dupati"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Meena Sree Dupati</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis (IUPUI), Indianapolis, USA<br />
                                        - Bachelor of Pharmacy
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Pursuing a Master of Science in Health Informatics at Indiana University-Purdue University Indianapolis, USA
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Meena Sree Dupati has excelled as a Data Science intern, successfully leading a key project centered on Electronic Health Records, specifically focusing on drug database management and drug interaction analysis. Her work involved the extraction and visualization of pathology data through advanced Python programming and machine learning techniques.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Throughout her internship, Meena has demonstrated exceptional dedication and commitment. Her ability to convey complex concepts with clarity has greatly enhanced team communication and collaboration. Meena's proactive approach to learning and growth is evident through her continuous pursuit of new knowledge and skills, particularly in managing extensive drug-related datasets and analyzing drug interactions.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Meena's contributions to the drug database project were particularly noteworthy. She effectively integrated and managed comprehensive drug data, applied machine learning to identify and visualize drug interactions, and ensured precise data handling and analysis. Her strong prioritization and problem-solving skills were instrumental in the successful execution of the project.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Overall, Meena Sree Dupati's remarkable technical skills and her commitment to ongoing improvement have significantly benefited the team. Her innovative solutions and effective communication make her a valuable contributor and a promising future leader in the field of Data Science.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={srinija} alt="Srinija Kasaraneni"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Srinija Kasaraneni</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis (IUPUI), Indianapolis, USA<br />
                                        - Bachelor of Dental Surgery
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Pursuing a Master of Science in Health Informatics at Indiana University-Purdue University Indianapolis, USA
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Srinija Kasaraneni has demonstrated exceptional skill and dedication as a Data Science intern, successfully leading a critical project focused on Electronic Health Records with an emphasis on drug database management and drug interaction analysis. Her expertise in Python programming and machine learning has been pivotal in extracting and visualizing pathology data.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Throughout her internship, Srinija has consistently shown a strong commitment to her work. Her ability to clearly articulate complex concepts has significantly enhanced team communication and collaboration. Srinija’s proactive learning approach is evident through her continuous quest for new knowledge and skills, especially in managing extensive drug-related datasets and analyzing drug interactions.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        In her role, Srinija made noteworthy contributions to the drug database project. She effectively integrated and managed comprehensive drug data, applied machine learning algorithms to uncover and visualize drug interactions, and ensured accurate data analysis and handling. Her excellent task prioritization and problem-solving abilities were crucial to the successful completion of the project.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Srinija Kasaraneni’s technical prowess and dedication to ongoing improvement have had a substantial positive impact on the team. Her innovative approach and effective communication make her a valuable asset and a promising future leader in Data Science.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={SaiPriya} alt="Sai Priyanka Annadasu"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Sai Priyanka Annadasu</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - University of Findlay, Master of Science in Health Informatics, Findlay, Ohio, United States.<br />
                                        - KC.Reddy Institute of Pharmaceutical Sciences. Bachelors in pharmacy, Guntur, Andhra Pradesh, India
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Master of Science in Health Informatics – University of Findlay, Completion Date: December 2023<br />
                                        As a recent graduate, I am actively seeking opportunities in the field of health data analysis to apply my skills and knowledge gained during my master's program and internship. I am particularly interested in roles that allow me to leverage data analysis techniques to contribute to healthcare solutions and enhance patient outcomes.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        During my internship at GTECHNOLOGIES PTY LTD, I played a crucial role in developing an advanced Electronic Medical Record (EMR) system integrated with AI applications for healthcare facilities. I contributed to data collection, analysis, and model development, enhancing the efficiency and accuracy of medical record management. The project successfully integrated AI algorithms, enabling real-time clinical decision support, predictive analytics for disease progression, and personalized care planning.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={Akhila} alt="Akhila Kadari"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Akhila Kadari</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - University of Michigan, Master of Science in Health Information Technology, Dearborn, Michigan, United States.<br />
                                        - SVS Institute of Dental Sciences, Mahbubnagar, India
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Master of Science in Health Information Technology, University of Michigan, Completion Date: May 2024<br />
                                        As a recent graduate, I am actively seeking opportunities in the field of health data analysis to apply my skills and knowledge gained during my master's program and internship. I am particularly interested in roles that allow me to leverage data analysis techniques to contribute to healthcare solutions and enhance patient outcomes.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        During my internship at GTECHNOLOGIES PTY LTD, I played a crucial role in developing an advanced Electronic Medical Record (EMR) system integrated with AI applications for healthcare facilities. I contributed to data collection, analysis, and model development, enhancing the efficiency and accuracy of medical record management. The project successfully integrated AI algorithms, enabling real-time clinical decision support, predictive analytics for disease progression, and personalized care planning.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={Sameera} alt="Sameera Voora"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Sameera Voora</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis, Indianapolis, United States.<br />
                                        - Bachelor of Dental Surgery, Panineeya Institute of Dental Sciences and Research Center, Hyderabad, India
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Master of Science in Health Informatics, Indiana University Purdue University Indianapolis, Completion Date: May 2024<br />
                                        As a recent master's graduate in the realm of health data analytics, I am eager to explore career opportunities that harness my academic background and internship experience. I am especially keen on roles where I can utilize advanced data analysis methods to drive improvements in healthcare and positively impact patient outcomes.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        I am immensely grateful for the opportunity to develop an Electronic Medical Records (EMR) system, which not only honed my technical skills but also offered profound lessons in collaboration and continuous learning. My involvement in front-end programming and data science allowed me to explore the dynamic relationship between user needs and technological solutions within the healthcare sector. This project not only advanced my expertise in problem-solving and project management but also highlighted the critical role of technological innovations in simplifying complex challenges. Working alongside a diverse team reinforced my belief in the power of teamwork and diverse perspectives in creating effective solutions.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={SonyPriyaSurapaneni} alt="Sony Priya Surapaneni"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Sony Priya Surapaneni</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis, Indianapolis, United States.<br />
                                        - Bachelor of Dental Surgery, Drs Sudha and Nageswara Rao Siddhartha Institute of Dental Sciences, Chinoutpalli, Andhra Pradesh, India.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Master of Science in Health Informatics, Indiana University Purdue University, Indianapolis, Indiana, USA. Completion Date: May 2024<br />
                                        As a recent graduate in Health Informatics, I am excited to embark on a journey to explore career opportunities that align with my academic background. I am particularly drawn to roles where I can leverage advanced data analysis techniques to catalyze improvements in healthcare. With a strong foundation in programming languages, healthcare standards, and data visualization tools, I am well-equipped to excel in positions focused on utilizing data-driven approaches to revolutionize healthcare delivery. I am eager to apply my skills and contribute to driving positive changes in the healthcare landscape through innovative data analysis solutions.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        At GTechnology Pty Ltd, my Internship focused on developing an AI-integrated EMR system to modernize healthcare practices. This involved hands-on experience in data gathering, preprocessing, and model development, enhancing my skills in SQL and Python. The project aimed to streamline medical record management, improve clinical decision-making, and enhance patient care, laying the foundation for my future in healthcare data analytics and technology development.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={SowmyaReddy} alt="Sowmya Reddy Papagari"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Sowmya Reddy Papagari</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis, Indianapolis, United States.<br />
                                        - Doctor of Pharmacy - PharmD, Mallareddy Pharmacy College, Telangana, India.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Master of Science in Health Informatics, Indiana University Purdue University, Indianapolis, Indiana, USA. Completion Date: May 2024<br />
                                        As a recent graduate in Health Informatics, I am excited to embark on a journey to explore career opportunities that align with my academic background. I am particularly drawn to roles where I can leverage advanced data analysis techniques to catalyze improvements in healthcare. With a strong foundation in programming languages, healthcare standards, and data visualization tools, I am well-equipped to excel in positions focused on utilizing data-driven approaches to revolutionize healthcare delivery. I am eager to apply my skills and contribute to driving positive changes in the healthcare landscape through innovative data analysis solutions.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Practicum Project: EMR Database Expansion and Interface Development<br />
                                        Mentor: Mr. Rajeshwar Reddy Konkisa<br />
                                        During my practicum at GTechnologies Pty Ltd, I spearheaded the integration of AI into Electronic Medical Records (EMR) systems, aiming to revolutionize healthcare data management. This endeavor was not just about advancing my academic and professional journey but also about enhancing patient care through cutting-edge technology. By leveraging AI's capabilities, we aimed to streamline data analytics and decision-making processes within EMR systems, ultimately paving the way for more efficient and effective healthcare delivery.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Under the guidance of my mentor, Mr. Rajeshwar Reddy Konkisa, I navigated the complexities of health informatics, working towards a future where EMR systems serve as powerful tools for medical professionals. Our project set a new benchmark in the healthcare industry, demonstrating the immense potential of AI in transforming traditional practices and improving patient outcomes. Through dedication and innovation, we forged a path towards a more interconnected and data-driven healthcare ecosystem, laying the foundation for future advancements in the field.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-edu-specialist-main">
                        <div className="slide-content-spec">
                            <div className="logo-block-spec"><img className="gridlogo-spec" src={VarshiniS} alt="Varshini Singamaneni"></img></div>
                            <div className="text-block-spec">
                                <ul>
                                    <h2 className="slider__caption">Varshini Singamaneni</h2>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Educational Background:</strong><br />
                                        - Master of Science in Health Informatics, Indiana University-Purdue University Indianapolis, Indianapolis, United States.<br />
                                        - Bachelor of Dental Surgery, Dr. N.T.R University of Health Sciences, Andhra Pradesh, India.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Current Enrollment:</strong><br />
                                        Master of Science in Health Informatics, Indiana University Purdue University, Indianapolis, Indiana, USA. Completion Date: May 2024<br />
                                        As a recent graduate in Health Informatics, I am enthusiastic about exploring career paths that resonate with my academic background. I am particularly interested in roles where I can apply advanced data analysis techniques to enhance healthcare outcomes. With a solid grasp of programming languages, healthcare standards, and data visualization tools, I am well-prepared to excel in positions centered on leveraging data-driven approaches to transform healthcare delivery. I am eager to utilize my skills to contribute to meaningful advancements in healthcare through innovative data analysis solutions.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        <strong>Project Accomplishments:</strong><br />
                                        Practicum Project: AI Precision Radiology: Redefining Chest X-ray Analysis<br />
                                        Mentor: Mr. Rajeshwar Reddy Konkisa<br />
                                        During my practicum at GTechnologies Pty Ltd, I led the initiative to integrate AI into Electronic Medical Records (EMR) systems, with the goal of revolutionizing healthcare data management. This experience was pivotal not only for my academic and professional growth but also for enhancing patient care through advanced technology. By harnessing AI's capabilities, our objective was to streamline data analytics and decision-making processes within EMR systems, thereby advancing the efficiency and effectiveness of chest X-ray analysis and healthcare delivery as a whole.
                                    </p>
                                    <p className="slider__txt" style={{ textAlign: 'justify' }}>
                                        Under the mentorship of Mr. Rajeshwar Reddy Konkisa, I delved into the complexities of health informatics, envisioning a future where AI-powered EMR systems revolutionize medical practices. Our collaborative project established a new standard in the healthcare industry, showcasing AI's transformative potential in enhancing traditional methods and optimizing patient outcomes. Through perseverance and innovative thinking, we paved the way for a more interconnected and data-driven healthcare landscape, laying a solid groundwork for future advancements in precision radiology.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default InternsPresentPast;
