import React from 'react';
import genai from '../icons/genai.avif';
import medicImg from '../icons/medicImg.avif';
import cloudMas from '../icons/cloudMas.avif';
import training from '../icons/training.jpeg';

function ProdServicesDetails() {
  return (
    <div className="information-sections">        
        <div className="step-grid">
        <div className="step" style={{ backgroundImage: `url(${genai})` }}>                               
                <label className="question-working-sub">GENERATIVE AI</label>
                <div className="content-answer">We harness the transformative potential of Generative AI to revolutionize multiple industry sectors. From advancing medical diagnostics and treatments to optimizing hotel operations and pioneering breakthroughs in mechanics, our AI-driven solutions not only enhance productivity but also significantly minimize errors. Our commitment is rooted in leveraging the nuances of Generative AI to deliver precise, efficient, and innovative applications, ensuring that industries can transcend traditional limitations and embrace a future marked by accuracy, agility, and excellence.</div>
            </div>
            
            <div className="step" style={{ backgroundImage: `url(${medicImg})` }}>
                <label className="question-working-sub">MEDICAL IMAGING</label>
                <div className="content-answer">At GTechnologies, we've dedicated our expertise and technological prowess to the noble cause of enhancing medical imaging. Recognizing the immense pressures and challenges faced by healthcare professionals, particularly doctors, our advanced solutions aim to streamline diagnostic processes and improve clarity. By optimizing and automating facets of medical imaging, we have successfully reduced the cognitive and operational load on doctors, enabling them to focus more on patient care and decision-making. Our steadfast commitment is to ensure that medical practitioners are equipped with the most refined tools, ensuring timely and accurate patient diagnoses and, ultimately, better health outcomes.</div>
            </div>     

            <div className="step" style={{ backgroundImage: `url(${cloudMas})` }}>
                <label className="question-working-sub">PYTHON AND CLOUD MASTERY</label>
                <div className="content-answer">We specialize in navigating the dynamic landscapes of Python programming and prominent Cloud platforms, including AWS and Azure. Recognizing the pivotal role of robust software solutions and efficient cloud infrastructures in today's digital era, we've dedicated ourselves to provide our clients with precision-engineered solutions. Our team of seasoned experts delves deep into each project, ensuring that every Python script is optimized for performance and every cloud implementation is scaled for future growth. By partnering with us, clients not only harness the power of cutting-edge technology but also position themselves at the forefront of innovation and operational excellence.</div>
            </div> 

            <div className="step" style={{ backgroundImage: `url(${training})` }}>
                <label className="question-working-sub">COURSES AND TRAININGS</label>
                <div className="content-answer">At GTechnologies, we firmly believe in the transformative power of continuous learning and skill enhancement. To this end, we've meticulously designed a wide array of courses and training sessions, catering to various needs and proficiency levels. Whether you're a novice seeking foundational knowledge or a professional aiming to upskill, our curriculum is crafted to propel you towards your goals. Under the guidance of our seasoned educators and industry experts, participants are ensured not only theoretical insights but also practical, hands-on experiences. Through our comprehensive educational offerings, we are committed to fostering an environment of growth, innovation, and lifelong learning.</div>
            </div>  
        </div>
    </div>
  );
}

export default ProdServicesDetails;
