import React from 'react';
import keyStethImage from '../icons/GTechnology-Logo-remove.png' // Import the image based on the given directory structure

function JumbotronInterns() {
  return (
    <div className="jumbotron-mv-main">
        <div className="heading-prod">Our Interns
            <div className="content-prod">Shaping the Future, One Innovation at a Time. Meet the Bright Minds Redefining Tomorrow.</div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>        
  );
}

export default JumbotronInterns;