import React from 'react';
import keyStethImage from '../icons/All-in-one-partner.jpg'

function AboutUSMissionVision() {
  return (
    <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading">Our Partners
            <div className="content">            
            At the heart of GTechnologies Pty Ltd's successes and forward-thinking innovations lie our invaluable partners. 
            These are esteemed cloud and solution providers who have proven to be instrumental in enabling us to deliver 
            state-of-the-art solutions. Their robust platforms and technologies complement our expertise, 
            allowing us to offer a wider spectrum of services and ensuring that our clients receive only the best. 
            We are deeply committed to fostering and nurturing these relationships, recognizing that our collaborative synergy 
            is what truly sets us apart in today's competitive landscape.
            </div>
        </div>        
    </div>    
  );
}

export default AboutUSMissionVision;
