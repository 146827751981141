import React from 'react';

function PrivacyPolicyInfo() {
  return (
    <div className="information-sections">
        <label className="question-working">Introduction</label>
        <div className="content-answer">Welcome to GTechnologies. Your privacy is of paramount importance to us. This privacy policy outlines our practices concerning the collection, use, and sharing of your personal information. By visiting our website and using our services, you agree to the terms outlined in this policy. This policy reflects our adherence to Australian health and data protection standards, including the Australian Privacy Principles (APPs) and the Privacy Act 1988 (Cth).</div>
        
        <div className="step-grid-priv-pol">
            <div className="step-priv-pol">              
                <label className="question-working-sub">Data Collection</label>
                <div className="content-answer">We collect information you provide directly to us, such as when you create an account, use our services, or communicate with us. We also automatically collect data when you use our services, including log information, device information, and usage data.</div>
                <div className="content-answer"></div>

                <label className="question-working-sub">Use of Data</label>
                <div className="content-answer">The information we collect is used to provide, maintain, and improve our services. We may also use the information to communicate with you, provide customer service, and tailor our marketing efforts.</div>
                <div className="content-answer"></div>

                <label className="question-working-sub">Data Sharing and Transfer</label>
                <div className="content-answer">We do not sell or share your personal information with third-party advertisers or marketers. We may share data with third-party vendors, consultants, and other service providers who perform services on our behalf and under our instructions.</div>
                <div className="content-answer"></div>

                <label className="question-working-sub">Data Protection and Security</label>
                <div className="content-answer">We implement robust security measures to ensure the safety of your data. While we strive to protect your personal information, we cannot guarantee its absolute security.</div>
                <div className="content-answer"></div>
                
                <label className="question-working-sub">Policy Updates</label>
                <div className="content-answer">We may update this policy periodically. Any changes will be posted on this page with an updated revision date.</div>
                <div className="content-answer"><strong>Thank you for trusting GTechnologies. Your privacy remains paramount to us.</strong></div>
            </div>
            
        </div>
    </div>
  );
}

export default PrivacyPolicyInfo;
