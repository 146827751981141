import React from 'react';
import trainingSessions from '../icons/trainingSessions.jpeg';
import interns from '../icons/interns.avif';

function EducationInternship() {
    return (
        <div className="information-sections-edu">
        <label className="question-working">Internships and Training Sessions</label>
        <div className="content-answer">We believe in nurturing the next generation of industry leaders by offering them more than just theoretical knowledge. Our internship and training sessions are meticulously designed to bridge the gap between classroom instruction and real-world application. Every intern is granted the opportunity to immerse themselves in hands-on projects, guided by a team of our seasoned professionals who are dedicated to mentoring and sharing their vast industry knowledge. This ensures that every student not only gains practical insights into their chosen field but also experiences the challenges and triumphs that define the daily life of a professional. By investing in these young minds, we are shaping the future of the industry, one experience at a time</div>
        
        <div className="step-grid">
            <div className="step" style={{ backgroundImage: `url(${trainingSessions})` }}>                               
                <label className="question-working-sub">TRAINING SESSIONS</label>
                <div className="content-answer">At GTechnologies, we pride ourselves on being at the forefront of technological advancements and educational synergy. Collaborating closely with top-tier edtech platforms like Edureka, we curate specialized training sessions tailored for the ambitious students keen on delving deep into the realm of Artificial Intelligence. But we don't stop at just training; we elevate their learning journey by entrusting them with real-life AI projects. These projects, under the mentorship of our AI experts, provide students with invaluable exposure to the nuances and intricacies of real-world challenges. By integrating theoretical knowledge with hands-on experience, we're crafting the AI pioneers of tomorrow and bolstering the tech ecosystem with innovation and expertise.</div>
            </div>
            
            <div className="step" style={{ backgroundImage: `url(${interns})` }}>
                <label className="question-working-sub">INTERNSHIPS</label>
                <div className="content-answer">At GTechnologies, we are steadfastly committed to fostering the growth of emerging talent and bridging academic excellence with industry relevance. In this endeavor, we have been privileged to offer internships to bright students from renowned institutions like Indiana University-Purdue University. Welcoming scholars from diverse fields, including the intricate domain of health informatics, we ensure a comprehensive immersion into the industry's intricacies. Our multifaceted projects and nurturing mentorship have consistently been lauded by these young enthusiasts, reflecting our dedication to creating a transformative and enriching experience. In cultivating these partnerships with academia, we are not just shaping individual careers, but also fortifying the industry's future with fresh perspectives and innovative ideas.</div>
            </div>         
        </div>
    </div>
    );
}

export default EducationInternship;
