import React from 'react';
import logoImage from '../icons/GTechnology-Logo.jpg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="jumbotron-aboutUs">          
               
        <div className="contact-footer">              
            <div className="content-answer-footer">
            <label className="Contact-values"><i className="fas fa-map-marker-alt"></i> Address(Australia)</label>
            <h6 className="values">GTechnologies Pty Ltd, 20 Dempsey, St Denham Court New South Wales 2565 Australia</h6>

            <label className="Contact-values"><i className="fas fa-map-marker-alt"></i> Address(Australia)</label>
            <h6 className="values">Gayatri Technologies Pty Ltd, 20 Dempsey, St Denham Court New South Wales 2565 Australia</h6>

            <label className="Contact-values"><i className="fas fa-mobile-alt"></i> Australian Company Number (ACN)</label>
            <h6 className="values">629175211</h6>

            <label className="Contact-values"><i className="fas fa-mobile-alt"></i> Australian Business Number (ABN)</label>
            <h6 className="values">58629175211</h6>

             <label className="Contact-values"><i className="fas fa-mobile-alt"></i> Contact Number/Mobile Number</label>
             <h6 className="values">+61426244089</h6>

            <label className="Contact-values"><i className="fas fa-envelope"></i> Email</label>
            <h6 className="values">rkonkisa@gtechnologies.com.au</h6>

            <label className="Contact-values"><i className="fas fa-link"></i><Link to="/privacy-policy" ><h6>Privacy Policy</h6></Link></label>            
            </div>
        </div>
    </div>    
  );
}

export default Footer;
