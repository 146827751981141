import React from 'react';
import inputWindow from '../icons/inputWindow.jpg';
import guideWindow from '../icons/guideWindow.jpg';
import summaryWindow from '../icons/summaryWindow.jpg';
import MadasSir from '../icons/Dr.Madas.webp'
import RajSir from '../icons/RajSir.webp'
import keyStethImage from '../icons/AI_team.webp'

function AboutUsTeamDetails() {
    return (
        <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={RajSir} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading">Rajeshwar Reddy Konkisa<h5>Director</h5>
            <div className="content">            
                Mr.Rajeshwar is a highly skilled professional with a strong background in statistics, data science, and computer engineering. His educational qualifications include a MicroMasters in Statistics and Data Science from the Massachusetts Institute of Technology and a Post Graduation in Data Science from the Indian Institute of Technology Guwahati. He obtained his Bachelor of Engineering in Computer Technology from Kavikulguru Institute of Technology and Science, Nagpur University, and holds a Computer Engineering Diploma from S.N.M Polytechnic Khammam, India.
            </div>
            <div className="content"> 
                During 2007 to 2008, Mr.Rajeshwar gained valuable international experience while working in the United States. He contributed his expertise to prominent companies such as Merck in Whitehouse Station, NJ (with HCL America) and Prudential Financials in Shelton, CT. Throughout his career, he has also worked for multinational corporations including GE Capital, WIPRO Infotech, HSBC GLT, and HCL Technologies India.
            </div>
        </div>  
        <div className="content-extra">            
            With a career spanning 20 years, Mr. Rajeshwar has developed a strong focus in the fields of Data Science, Business Intelligence, and Data Warehousing. He possesses extensive knowledge and practical experience with various BI/DW technologies, including MSBI, IBM COGNOS, and Business Objects. In the past 12 years, he has worked with multiple Australian clients, collaborating with organizations such as Western Power, Integral Energy, Westpac (with HCL Australia), Department of Veterans’ Affairs, Primary Health Care, NSW Rail Corp (with Mahindra Satyam), Nuance Group, Optus Sydney (with Tech Mahindra), Department of Finance New South Wales, Department of Defence project (with Avanade Australia), Australian Red Cross Blood Service, Department of Communities and Justice and the Australian Department of Health.
        </div>     
        <div className="content-extra">            
            Currently, Mr.Rajeshwar holds the position of Health Manager at New South Wales Health in Sydney, Australia. His expertise lies in Python programming, Machine Learning, Deep Learning, Image Classification, Anomaly Detection, and Prediction. He possesses a versatile skill set that includes Data Extract, Transform and Loading (ETL) using tools such as Talend for Big Data, IBM Cognos Tools, TM1, Power BI, Python, Machine Learning/Deep Learning, AWS SageMaker, Machine Learning on AWS, TensorFlow, Snowflake, Cerner Millennium, and the Hadoop toolset, including Spark/Scala, Pyspark, Hive, and Kafka.
        </div> 
    </div> 
    );
}

export default AboutUsTeamDetails;
