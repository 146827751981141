import React from 'react';
import keyStethImage from '../icons/team-gtech.webp'

function AboutUsTeam() {
  return (
    <div className="jumbotron-ourTeam">   
        <div className="heading">Our Team
            <div className="content">
            At GTechnologies Pty Ltd, we believe our most invaluable asset is our team. Comprising a diverse group of dedicated 
            professionals, each member brings a unique blend of expertise, passion, and innovation to the table. 
            Their combined knowledge spans across Artificial Intelligence, Cloud Computing, and myriad other technological 
            domains, ensuring that we remain at the forefront of our industry. Our team thrives on collaboration, 
            continuously pushing boundaries and setting new benchmarks. It's their unwavering commitment and shared vision that 
            truly drives our company forward, cementing our reputation for excellence.
            </div>
        </div>  
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>                      
    </div>    
  );
}

export default AboutUsTeam;
