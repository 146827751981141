import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import inputWindow from '../icons/inputWindow.jpg';
import healthCor from '../icons/healthCor.webp';
import sagemaker from '../icons/sagemaker.avif';
import course1 from '../icons/course1.jpg'

function UserFriendlyDesign() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
      };
    
    return (
        <div className="testimonials">
            <label className="question-working">Courses Launched</label>
            <div className="content-answer">We are excited to announce that we have embarked on a journey to share our expertise and insights with a wider audience. Leveraging the renowned platform of Udemy, we have meticulously crafted a series of courses designed to deliver both foundational knowledge and advanced concepts in our domain. These courses encapsulate our years of experience and are tailored to foster a comprehensive understanding for our learners.</div>
            <div className="content-answer">We invite you to explore our course offerings on Udemy. By engaging with our content, you will not only be enriching your knowledge but also joining a community dedicated to lifelong learning and professional growth. Do take a moment to check out our courses and let us help you navigate the path to mastery in your chosen field.</div>         
            <div className="content-answer"></div>  

            <div className="testimonials">
                <Slider {...settings}>
                    <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec"><img className="gridlogo-spec" src={sagemaker} alt="Document Accuracy"></img></div>
                        <div className="text-block-spec"> 
                            <ul>
                                <h2>NumPy, Pandas, Matplotlib in Python using Amazon SageMakerLearn Python using Amazon SageMaker</h2>
                                <p>We are thrilled to present our latest course titled ‘Learn Python using Amazon SageMaker: NumPy, Pandas, Matplotlib’. This course is designed to provide you with comprehensive training in utilizing NumPy, Pandas, and Matplotlib libraries within the context of Python programming, all through the powerful platform of Amazon SageMaker. We are excited to offer you this opportunity to enhance your Python skills while leveraging the capabilities of Amazon SageMaker.</p> 
                                <p><a className="link" href="https://www.udemy.com/course/learn-python-using-amazon-sagemaker/">NumPy, Pandas, Matplotlib in Python using Amazon SageMaker | Udemy</a></p> 
                                <p>Elevate your skills with our expert-led online tutorials available on Udemy. We are excited to announce the launch of our highly sought-after Python course! Whether you are a coding beginner or aiming to level up your skills to secure your dream job, our course, ‘NumPy, Pandas, Matplotlib in Python using Amazon SageMaker: Learn Python using Amazon SageMaker’, is perfect for you. By enrolling in this course, you will gain unlimited access to online learning resources and have the opportunity to practice industry-level projects. Take the next step in advancing your career with this comprehensive Python course.</p>                
                            </ul>                     
                        </div>
                    </div>
                    </div>
                    <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec"><img className="gridlogo-spec" src={healthCor} alt="Empowering the Surgeon"></img></div>
                        <div className="text-block-spec">
                            <ul>                            
                                <h2>Machine Learning Projects for Healthcare</h2>
                                <p>This course aims to offer students a practical and accessible approach to understanding essential concepts related to neural networks and machine learning techniques. Its primary goal is to equip students with the knowledge they need in a straightforward manner.</p> 
                                <p><a className="link" href="https://www.udemy.com/course/machine-learning-projects-for-healthcare/">Machine Learning Projects for Healthcare | Udemy</a></p> 
                                <p>Elevate your skills with our expert-led online tutorials available on Udemy. We are thrilled to introduce our latest course offering, ‘Machine Learning Projects for Healthcare’. This course is an invaluable resource for Data Scientists keen on transitioning their machine learning knowledge into actionable healthcare solutions. It's also a beacon for Python and Machine Learning enthusiasts who aspire to bolster their project portfolio with relevant healthcare undertakings. Moreover, for those individuals driven by curiosity about the transformative power of machine learning and AI, our course provides a deep dive into its applications in the medical and healthcare domain. By enrolling, you'll unlock a plethora of online learning resources and immerse yourself in projects that mirror real-world challenges. Venture into the intersection of technology and healthcare and take a significant leap in advancing your career with our comprehensive course on Machine Learning Projects for Healthcare.</p>        
                            </ul>
                        </div>
                    </div>
                    </div>
                    <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec"><img className="gridlogo-spec" src={course1} alt="Empowering the Surgeon"></img></div>
                        <div className="text-block-spec">
                        <ul>
                            <h2>AWS Certified Machine Learning Specialty Full Practice Exam</h2>
                            <p>AWS has developed a Machine Learning pathway to enable professionals to evaluate their skills and experience in developing, fine-tuning, training, and deploying ML models using AWS cloud services.Preparing for the AWS Machine Learning Specialty exam can be quite challenging and requires dedication, hard work, and the right resources to excel. While there are numerous resources available, it’s important to identify the ones that are most beneficial for your preparation. By utilizing efficient resources, you can maximize your learning in a shorter period of time, allowing for more practice and revision opportunities.</p> 
                                    <p><a className="link" href="https://www.udemy.com/user/rajeshwar-107/">AWS Certified Machine Learning Specialty Full Practice Exam | Udemy</a></p> 
                            <p>This examination is crafted to evaluate your expertise in designing, implementing, deploying, and maintaining machine learning (ML) solutions tailored for distinct business challenges. It seeks to validate your capacity to choose and justify the right ML approach for specific business scenarios, pinpoint the ideal AWS services for ML solution execution, and design as well as implement ML strategies that stand out in scalability, cost-efficiency, reliability, and security.</p>                      
                        </ul>
                        </div>
                    </div>
                    </div>                            
                </Slider>
                </div>            
        </div>
    );
}

export default UserFriendlyDesign;
