import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fontPage from '../icons/manCome.png';
import wordCloud from '../icons/wordCloud.jpg';
import brainTumor from '../icons/brainTumor.jpg';
import guidedProj from '../icons/guidedProj.jpg';
import aiHealth from '../icons/aiHealth.png';

function ProjectDetails() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000, // 10 seconds
    };

    return (
        <div className="testimonials">
            <Slider {...settings}>
                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={fontPage} alt="Upholding Trust & Security" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2>AI-Driven Excellence in Electronic Health Records</h2>
                                <li><strong>Data Science Internship Program</strong></li>
                                <li><strong>Responsibilities:</strong></li>
                                <li>Database Management: Design and maintain databases in MySQL to store and manage electronic health records.</li>
                                <li>Front-End Development: Develop front-end applications using React.js to create user-friendly interfaces.</li>
                                <li>Machine Learning Implementation: Utilize Visual Studio and Python to develop and implement machine learning models for predictive analytics.</li>
                                <li>Data Analysis: Integrate MySQL for advanced statistical analysis and data manipulation.</li>
                                <li>Cross-Functional Collaboration: Coordinate with cross-functional teams to ensure seamless integration of AI-driven solutions into existing systems.</li>
                                <li><strong>Skills and Qualifications:</strong></li>
                                <li>Experience with MySQL or other relational databases.</li>
                                <li>Proficiency in front-end development using React.js.</li>
                                <li>Knowledge of Visual Studio and Python for machine learning.</li>
                                <li>Ability to perform advanced data analysis and manipulation.</li>
                                <li>Strong communication and teamwork skills.</li>
                                <li><strong>Additional Information:</strong></li>
                                <li>The intern will have the opportunity to work on cutting-edge projects that leverage AI to enhance electronic health records.</li>
                                <li>This role requires the ability to prioritize tasks effectively to ensure timely completion and smooth progress of projects.</li>
                                <li>A commitment to excellence and continuous professional growth is essential.</li>
                                <li>This internship offers a chance to make significant contributions to the team while gaining valuable experience in a dynamic and innovative environment.</li>
                                <li>We are looking for a highly motivated and skilled individual who is passionate about learning and dedicated to innovation.</li>
                                <li>If you are looking for a role that will challenge you and help you grow, we highly recommend applying for this position.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={wordCloud} alt="Document Accuracy" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2>Topic Modelling:</h2>
                                <li><strong>Collaboration with Accrete.ai:</strong> We partnered with Accrete.ai to initiate a topic modelling project designed specifically for students, aiming to provide a hands-on experience in the realm of data science and artificial intelligence.</li>
                                <li><strong>Utilizing Latent Dirichlet Allocation (LDA):</strong> Central to the project was the application of Latent Dirichlet Allocation (LDA), a generative statistical model, which allowed students to decipher various topics from a collection of text data.</li>
                                <li><strong>Implementation of Advanced Topic Modelling Techniques:</strong> Aside from LDA, the project encompassed other sophisticated topic modelling techniques, further expanding the students' analytical abilities.</li>
                                <li><strong>Focus on Current News Trends:</strong> The project involved a focused analysis of the latest news trends, offering students an opportunity to integrate real-time data into their research. This not only equipped them with contemporary insights but also fostered critical thinking and data interpretation skills.</li>
                                <li><strong>Outcome and Skill Development:</strong> As a result of the project, students were able to identify and understand the nuances of current news trends more adeptly, fostering a deeper understanding of the dynamics of news analytics, and preparing them for potential careers in data science and analytics.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={brainTumor} alt="Empowering the Surgeon" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2>Brain Tumor Segmentation:</h2>
                                <li><strong>Partnership with University of Sharjah:</strong> We successfully completed a brain tumour segmentation project in collaboration with the University of Sharjah, uniting academic research with practical applications in the medical field.</li>
                                <li><strong>Application of UNET Architecture:</strong> A pivotal part of the project involved leveraging the UNET architecture, a renowned neural network, to facilitate precise segmentation of brain tumours, enhancing the efficiency and accuracy of the diagnosis process.</li>
                                <li><strong>Incorporation of Ensemble Models:</strong> The project also saw the integration of ensemble models, combining multiple deep learning models to improve the reliability and performance of tumour detection, thereby assisting in more accurate patient assessments.</li>
                                <li><strong>Utilization of Deep Learning Techniques:</strong> Beyond UNET and ensemble models, we employed a range of other deep learning techniques, thereby developing a robust system capable of aiding doctors in patient prioritization with enhanced analytical depth.</li>
                                <li><strong>Aiding Medical Professionals:</strong> Ultimately, the project aimed to serve the medical community by offering a sophisticated tool that assists doctors in prioritizing patients effectively, potentially expediting treatment processes and improving healthcare outcomes.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={guidedProj} alt="Operational Prowess" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2>Various Guided Projects:</h2>
                                <li><strong>Collaboration with Edureka:</strong> We embarked on a fruitful collaboration with Edureka, where we curated a series of guided projects aimed at fostering a deeper understanding and skill development in students, particularly in areas concerning medical data analysis and diagnostics.</li>
                                <li><strong>Diabetic Retinopathy Project:</strong> One of the flagship projects focused on Diabetic Retinopathy, where students were guided to explore, analyze, and propose solutions for early detection and management of this diabetes-related eye disease, enhancing their proficiency in medical data analysis.</li>
                                <li><strong>Autism Screening Initiative:</strong> Students were also engaged in an autism screening project, where they utilized various data analysis techniques to develop potential screening tools, thereby sharpening their expertise in the field of healthcare analytics and contributing to early diagnosis initiatives.</li>
                                <li><strong>Hands-On Experience with Basic Projects:</strong> Besides the specialized projects, we facilitated other basic projects that were designed to cement the students' foundational understanding of various analytical techniques and methodologies, thus broadening their knowledge base and skill set.</li>
                                <li><strong>Enhancing Student Understanding:</strong> Through the comprehensive project offerings, we aimed to augment the students' understanding and hands-on experience in tackling real-world challenges, ultimately preparing them to make meaningful contributions in their future careers.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={aiHealth} alt="Evolution in Training" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2>AI for Healthcare Projects:</h2>
                                <li><strong>Multi-University Internship Initiative:</strong> We initiated a comprehensive internship program that welcomed students from various universities, providing them with the unique opportunity to delve into the fast-evolving field of Artificial Intelligence in the healthcare domain.</li>
                                <li><strong>Focused on Healthcare Domain:</strong> The core of the internship centered around the healthcare domain, where students were exposed to real-world challenges and advancements in AI applications, thereby enhancing their understanding and skills in healthcare technology.</li>
                                <li><strong>Practical Exposure to AI Applications:</strong> During the internship, students were immersed in hands-on projects that highlighted the pivotal role of AI in transforming healthcare, offering them practical experience and fostering innovation and critical thinking.</li>
                                <li><strong>Cross-Collaborative Learning Environment:</strong> The program created a vibrant and collaborative learning environment where students could interact with experts and peers from different universities, fostering a rich exchange of ideas and multidisciplinary approaches to problem-solving.</li>
                                <li><strong>Stepping Stone for Future Careers:</strong> Serving as a robust platform, the internship program equipped students with the skills and knowledge required to excel in their future careers, potentially spearheading the next wave of advancements in AI for healthcare.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={fontPage} alt="Upholding Trust & Security" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2>Many More to Come:</h2>
                                <li><strong>Expansion of Internship Program:</strong> As we progress, we anticipate expanding our reach by facilitating internships for at least 50 students from various US universities this year, reaffirming our commitment to fostering education and skill development in the AI for healthcare sector.</li>
                                <li><strong>Welcoming Diverse Educational Backgrounds:</strong> The upcoming internship program aims to be inclusive, welcoming students from a myriad of backgrounds including pharmacy and health informatics, thus encouraging a multidisciplinary approach to tackling challenges in the healthcare domain.</li>
                                <li><strong>Real-World Experience in Healthcare Sector:</strong> Our interns will be presented with the unique opportunity to gain real-world experience in the healthcare sector, working on projects that blend technology and healthcare to create innovative solutions for contemporary health issues.</li>
                                <li><strong>Cultivating Future Leaders:</strong> Through this initiative, we aspire to cultivate future leaders in the healthcare industry by equipping students with the necessary skills and knowledge that would serve as a solid foundation for their budding careers in the intersection of AI and healthcare.</li>
                                <li><strong>Looking Towards a Bright Future:</strong> With "many more to come", we are enthusiastic about the potential of this program to foster innovation and collaboration, shaping a bright future for both the students involved and the healthcare industry as a whole.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default ProjectDetails;
