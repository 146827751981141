import React from 'react';
import fontPage from '../icons/font_page.jpg';  // Import the image based on the given directory structure

function Jumbotron() {
  return (
    <div className="jumbotron-mv-main">
        <div className="heading">Crafting Cognition: Where AI Meets Imagination.
            <div className="content">Empowering You Daily, With Innovative AI Solutions, Transforming Life's Many Facets. </div>
        </div>
    </div>        
  );
}

export default Jumbotron;

