import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import fontPage from '../icons/manCome.png';
import indi from '../icons/indi.jpg';
import uniFin from '../icons/uniFin.jpg';
import sacHeart from '../icons/sacHeart.png';
import aiHealth from '../icons/aiHealth.png';
import dearborn from '../icons/dearborn.jpg';
import gsvu from '../icons/gvsu.jpg';
import uwhc from '../icons/university-of-wisconsin-milwaukee-vector-logo.png';
import uod from '../icons/UOD.jpg';
import uomb from '../icons/UOMB.jpg';
import uot from '../icons/UOT.jpg';
import pu from '../icons/PU.jpg';

function CollegeCollaborations() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
  };

  return (
    <div className="testimonials">
      <Slider {...settings}>
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={indi} alt="Document Accuracy"></img></div>
            <div className="text-block-spec">                          
                <ul>
                    <h2>GTechnologies Pty Ltd & Indiana University Collaboration</h2>
                    <strong>GTechnologies Pty Ltd partnered with Health Informatics students from Indiana University to provide hands-on experience in the following projects:</strong>
                    <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                    <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                    <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                    <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                    <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                </ul>
            </div>
          </div>
           </div>
              <div className="step-edu-specialist-main">
                  <div className="slide-content-spec">
                      <div className="logo-block-spec"><img className="gridlogo-spec" src={pu} alt="Document Accuracy"></img></div>
                      <div className="text-block-spec">
                          <ul>
                              <h2>GTechnologies Pty Ltd & Prude University Collaboration</h2>
                              <strong>GTechnologies Pty Ltd partnered with IT students from Prude University to provide hands-on experience in the following projects:</strong>
                              <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                              <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                              <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                              <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                              <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                          </ul>
                      </div>
                  </div>
              </div>
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={uniFin} alt="Empowering the Surgeon"></img></div>
            <div className="text-block-spec">
                <ul>
                    <h2>GTechnologies Pty Ltd & University of Findlay Collaboration</h2>
                    <strong>GTechnologies Pty Ltd partnered with Health Informatics students from University of Findlay to provide hands-on experience in the following projects:</strong>
                    <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                    <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                    <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                    <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                    <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                </ul>
            </div>
          </div>
        </div>
         <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={dearborn} alt="Empowering the Surgeon"></img></div>
            <div className="text-block-spec">
                <ul>
                    <h2>GTechnologies Pty Ltd & University of Michigan-Dearborn</h2>
                    <strong>GTechnologies Pty Ltd partnered with students from University of Michigan-Dearborn to provide hands-on experience in the following projects:</strong>
                    <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                    <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                    <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                    <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                    <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                </ul>
            </div>
          </div>
        </div> 
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={gsvu} alt="Empowering the Surgeon"></img></div>
            <div className="text-block-spec">
                <ul>
                    <h2>GTechnologies Pty Ltd & Grand Valley State University</h2>
                    <strong>GTechnologies Pty Ltd partnered with students from Grand Valley State University to provide hands-on experience in the following projects:</strong>
                    <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                    <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                    <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                    <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                    <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                </ul>
            </div>
          </div>
        </div>
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={uwhc} alt="Empowering the Surgeon"></img></div>
            <div className="text-block-spec">
                <ul>
                    <h2>GTechnologies Pty Ltd & University of Wisconsin</h2>
                    <strong>GTechnologies Pty Ltd partnered with students from University of Wisconsin to provide hands-on experience in the following projects:</strong>
                    <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                    <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                    <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                    <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                    <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                </ul>
            </div>
          </div>
             </div>
              <div className="step-edu-specialist-main">
                  <div className="slide-content-spec">
                      <div className="logo-block-spec"><img className="gridlogo-spec" src={uod} alt="Empowering the Surgeon"></img></div>
                      <div className="text-block-spec">
                          <ul>
                              <h2>GTechnologies Pty Ltd & University of Denver</h2>
                              <strong>GTechnologies Pty Ltd partnered with students from University of Denver to provide hands-on experience in the following projects:</strong>
                              <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                              <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                              <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                              <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                              <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                          </ul>
                      </div>
                  </div>
              </div>
               
              <div className="step-edu-specialist-main">
                  <div className="slide-content-spec">
                      <div className="logo-block-spec"><img className="gridlogo-spec" src={uomb} alt="Empowering the Surgeon"></img></div>
                      <div className="text-block-spec">
                          <ul>
                              <h2>GTechnologies Pty Ltd & University of Maryland Baltimore County</h2>
                              <strong>GTechnologies Pty Ltd partnered with students from University of Maryland Baltimore County,Maryland,USA to provide hands-on experience in the following projects:</strong>
                              <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                              <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                              <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                              <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                              <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="step-edu-specialist-main">
                  <div className="slide-content-spec">
                      <div className="logo-block-spec"><img className="gridlogo-spec" src={uot} alt="Empowering the Surgeon"></img></div>
                      <div className="text-block-spec">
                          <ul>
                              <h2>GTechnologies Pty Ltd & University of Texas</h2>
                              <strong>GTechnologies Pty Ltd partnered with students from University of Texas, USA to provide hands-on experience in the following projects:</strong>
                              <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                              <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                              <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                              <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                              <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="step-edu-specialist-main">
                  <div className="slide-content-spec">
                      <div className="logo-block-spec"><img className="gridlogo-spec" src={sacHeart} alt="Empowering the Surgeon"></img></div>
                      <div className="text-block-spec">
                          <ul>
                              <h2>GTechnologies Pty Ltd & Sacred Heart University Collaboration</h2>
                              <strong>GTechnologies Pty Ltd partnered with students from Sacred Heart University to provide hands-on experience in the following projects:</strong>
                              <li>Developing skills in extracting critical health information and analyzing medical reports, particularly focusing on pathology, to derive critical insights for healthcare advancements.</li>
                              <li>Embarking on innovative projects in the field of drug discovery, exploring potential avenues for new treatments and understanding drug adversarial effects.</li>
                              <li>Implementing a medical imaging project focused on detecting pneumonia from X-rays, promoting innovation in healthcare diagnostics.</li>
                              <li>Working jointly to create a proprietary Electronic Health Record (EHR) system, fostering a more efficient healthcare experience.</li>
                              <li>Converting manuscript clinical notes to legible text format within the Electronic Medical Record (EMR) system, enhancing data accessibility and readability.</li>
                          </ul>
                      </div>
                  </div>
              </div>
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={fontPage} alt="Empowering the Surgeon"></img></div>
            <div className="text-block-spec">
                          <ul>
                              <strong><strong>List of few Universities:</strong> </strong>
                              <li>Indiana University Purdue University Indianapolis,USA</li>
                              <li>Purdue University,USA</li>
                              <li>University of Findlay,USA</li>
                              <li>University of Michigan,USA</li>
                              <li>Grand Valley State University,USA</li>
                              <li>University of Texas,USA</li>
                              <li>University of North Texas,USA</li>
                              <li>University of Wisconsin-Milwaukee,USA</li>
                              <li>University of Maryland Baltimore County, Maryland,USA</li>
                              <li>University of Denver,USA</li>
                              <li>Belhaven University,USA</li>
                              <li>Trine University,USA</li>
                              <li>Northeastern University,Boston,USA</li>
                              <li>Michigan Technological University,Houghton,MI,USA</li>
                              <li>State University NY Oswego,USA</li>
                              <li>California State University,Long Beach,California,USA</li>
                              <li>Sacred Heart University,USA</li>
                              <li>Royal Melbourne Institute of Technology,Australia</li>
                              <li>Monash University,Australia</li>
                              <li>Gandhi Institute of Technology and Management,Bengaluru,Karnataka,India</li>
                              <li>Saveetha School Of Engineering,Chennai,Tamil Nadu,India</li>
                              <li>Crescent Institute of Technology,Chennai,Tamil Nadu,India</li>
                          </ul>

            </div>
          </div>
              </div>        
              <div className="step-edu-specialist-main">
                  <div className="slide-content-spec">
                      <div className="logo-block-spec"><img className="gridlogo-spec" src={fontPage} alt="Empowering the Surgeon"></img></div>
                      <div className="text-block-spec">
                          <strong><strong><strong>List of Artificial Intelligence, Machine Learning and Deep Learning projects include:</strong></strong></strong>
                          <ul style={{ fontSize: '1.0em' }}>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>PathoVision: Automated Pathology Data Extraction and Visualization using Machine Learning.</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>X-Ray Insight: Advanced EMR Enhancement through Machine Learning.</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>EHR: AI-Driven Excellence in Electronic Health Records with MySQL, DBeaver, Reactjs, Visual Studio, Python, and Machine Learning.</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>Automated Brain Tumour Segmentation Using Deep Learning: A Comparative Study of U-Net and Ensemble Models.</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>Automated Detection of Condylar Morphological Abnormalities from CBCT Imaging Using Deep Learning.</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>Artificial Intelligence (AI) - Diabetes Prediction Model Using Medical History</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>Machine Learning (ML) - AI-Based Pneumonia Detection from Chest X-ray Images</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>Machine Learning (ML) - Predicting Dementia Risk with Machine Learning</strong>
                              </li>
                              <li>
                                  <strong style={{ fontSize: '1.0em' }}>Deep Learning - Alzheimer's Disease Detection Using Deep Learning Techniques</strong>
                              </li>
                          </ul>

                      </div>
                  </div>
              </div>        
      </Slider>
    </div>
  );
}

export default CollegeCollaborations;
