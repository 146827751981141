import React from 'react';
import keyStethImage from '../icons/GTechnology-Logo.jpg'

function AboutUsJumbo() {
  return (
    <div className="jumbotron-aboutUs">
        <div className="heading">About Us
            <div className="content">
            Established in 2019, GTechnologies Pty Ltd operates as an esteemed affiliate of Gayatri Technologies Pty Ltd. 
            Headquartered in Sydney, Australia, GTechnologies Pty Ltd excels in delivering avant-garde solutions powered by 
            Artificial Intelligence and Cloud Computing. Our proficiency encompasses a broad spectrum of areas including AI, 
            Machine Learning, Deep Learning, Data Science, Natural Language Processing, Medical Imaging, Computer Vision, and 
            expertise in cloud platforms such as AWS and Azure. With an unwavering commitment to understanding and addressing 
            the unique needs of our clientele, we consistently strive to offer innovative and bespoke solutions.
            </div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>
  );
}

export default AboutUsJumbo;
