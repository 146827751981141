import React from 'react';

function Copyright() {
  return (
    <div className="copyright-container">
        <div className="copyright-text">Copyright © 2023 GTechnology: Crafting Cognition - All Rights Reserved.</div>
        <div className="powered-by">Powered by&nbsp;<a className="link" href="https://gtechnologies.com.au/">GTechnologies Pty Ltd</a>, Sydney</div>
    </div>
  );
}

export default Copyright;
