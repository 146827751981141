import React from 'react';
import inputWindow from '../icons/inputWindow.jpg';
import guideWindow from '../icons/guideWindow.jpg';
import summaryWindow from '../icons/summaryWindow.jpg';
import praveensir from '../icons/praveensir.jpg'
import RajSir from '../icons/RajSir.webp'
import pravin2 from '../icons/pravin2.jpeg'

function AboutUsPraveen() {
    return (
        <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={praveensir} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading">Praveen Kumar Reddy Konkisa<h5>Executive Director</h5>
            <div className="content">            
                Mr. Praveen holds a Master of Technology in Computer Science & Engineering from Osmania University, India, and a Bachelor of Civil Engineering from Kakatiya Institute of Technology and Science, Kakatiya University, India. With a robust experience of 25 years in the IT industry, he has gained valuable expertise through various international ventures.
            </div>
            <div className="content"> 
                In 2002, he spent nearly a year in the USA, working at Microsoft Technology Center – Seattle. Following that, he contributed his skills for approximately 2 years in New Zealand with the Foundation for Research Science and Technology.
            </div>
        </div>  
        <div className="content-extra">            
            Since 2004, Mr. Praveen has been based in Australia, focusing on DW/BI (Data Warehousing/Business Intelligence) and Hadoop tools. His notable client engagements include Australian Government departments such as Department of Home Affairs, Australian Federal Police, and Department of Human Services. He has also worked with organizations like Tabcorp Holdings Limited, Teradata Corporation, Telstra Corporation Limited for the Department of Defence Project, Australian Securities and Investments Commission, Department of Veterans’ Affairs, Airservices Australia, Department of Health and Ageing, Defence Housing Australia, Foundation for Research Science and Technology, Stockland Development Pty Limited, Wipro Limited, Datamatics Global Services Limited, Mahindra Satyam – Microsoft Corporation Project, and McCreade Software (Asia) Pvt. Ltd.
        </div>     
        <div className="content-extra">            
            His skill set encompasses a wide range of tools and technologies, including Spark and Scala, HDFS (Hadoop Distributed File System), Hive, Nifi, Streamsets, and other Big Data/Hadoop tools.
        </div> 
    </div> 
    );
}

export default AboutUsPraveen;
