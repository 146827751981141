import React from 'react';

function SignupComponent() {
  // Handler to open the form link in a new tab
  const handleButtonClick = () => {
    window.open('https://forms.office.com/r/s9gfj4PKvk', '_blank');
  };

  return (
    <div className="signup-comp">
        <label className="question-working-signup">Join our journey towards impactful future with GTechnologies Pty Ltd</label>
        <div className="join-button">
            <button className="signup-button" onClick={handleButtonClick}>Request Your Personalized Interest</button>
        </div>
    </div>
  );
}

export default SignupComponent;
