import React from 'react';
import development from '../icons/development.jpg';
import consulting from '../icons/consulting2.avif';
import techsupport from '../icons/techsupport.avif';
import training from '../icons/training2.jpg';

function InformationSection() {
  return (
    <div className="information-sections">
        <label className="question-working">Focus Areas</label>
        <div className="content-answer">At GTechnologies, we harness the unparalleled potential of Artificial Intelligence (AI) and other cutting-edge technologies to drive innovation across various sectors. Our dedication to technological excellence allows us to delve deep into the nuances of each industry, tailoring solutions that align with specific needs and challenges. From healthcare and finance to entertainment and logistics, our technologically-advanced tools are designed to empower businesses, optimize processes, and shape the future. Join us in our journey to revolutionize industries with the power of AI.</div>
        
        <div className="step-grid">
            <div className="step" style={{ backgroundImage: `url(${development})` }}>                               
                <label className="question-working-sub">DEVELOPMENT</label>
                <div className="content-answer">Leveraging the prowess of AI, we craft advanced development solutions that set new benchmarks in industry standards. Our AI-driven methodologies streamline processes, ensuring faster product delivery and innovation.</div>
            </div>
            
            <div className="step" style={{ backgroundImage: `url(${consulting})` }}>
                <label className="question-working-sub">CONSULTING</label>
                <div className="content-answer">Our AI-centric consulting services empower businesses with insights and strategies tailored for the future. Dive into the world of AI with our experts to identify opportunities and reimagine your business model.</div>
            </div>
            
            <div className="step" style={{ backgroundImage: `url(${techsupport})` }}>                
                <label className="question-working-sub">TECHNICAL SUPPORT </label>
                <div className="content-answer">Experience next-level technical support enhanced by AI, ensuring swift problem resolution and proactive system maintenance. Our AI tools identify and troubleshoot issues, guaranteeing minimal downtime and peak performance.</div>
            </div>
            
            <div className="step" style={{ backgroundImage: `url(${training})` }}>
                <label className="question-working-sub">TRAINING</label>
                <div className="content-answer">With AI at the forefront, our training modules are designed to be intuitive, interactive, and insightful. Equip your team with the skills of tomorrow, harnessing the transformative power of AI in every lesson.</div>
            </div>         
           
        </div>
    </div>
  );
}

export default InformationSection;
